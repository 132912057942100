<template>
  <div class="label-info">
    <div class="select-label">
      <div style="width:100%;display:flex">
        <div class="select-condition" @click="conditionClick(1)" :style="{'border': labelCondition==1?'1px solid #387FF5':''}">或条件</div>
        <div class="select-condition" style="margin-left:12px" @click="conditionClick(2)" :style="{'border': labelCondition==2?'1px solid #387FF5':''}">且条件</div>
      </div>
      <div v-for="item in selectNameList" :key="item.value" class="select-item">{{item.label}} <van-icon name="cross" @click.native="cancelLabel(item.value)" /></div>
    </div>
    <!-- <div class="typeCheck">
      <span @click="labelShow = false">取消</span>
      <span>请选择</span>
      <span @click="comfirm">确定</span>
    </div> -->
    <div class="label-list">
      <van-checkbox-group v-model="selectList" @change="getSelectNameList">
        <van-cell-group v-for="item in list" :key="item.value">
          <van-cell>
            <template #title>
              <van-checkbox :name="item.value" icon-size="15px">{{item.label}}</van-checkbox>
            </template>
            <template #right-icon v-if="item.children && item.children.length > 0">
              <van-icon :name="item.isLeaf?'arrow-up':'arrow-down'" @click.native.stop="item.isLeaf=!item.isLeaf" />
            </template>
          </van-cell>
          <template v-if="item.isLeaf">
            <van-cell v-for="val in item.children" :key="val.value" title-style="padding-left:20px">
              <template #title>
                <van-checkbox :name="val.value" icon-size="15px">{{val.label}}</van-checkbox>
              </template>
              <template #right-icon v-if="val.children && val.children.length > 0">
                <van-icon :name="val.isLeaf?'arrow-up':'arrow-down'" @click.native.stop="val.isLeaf=!val.isLeaf" />
              </template>
              <template v-if="val.isLeaf">
                <van-cell v-for="v in val.children" :key="v.value" title-style="padding-left:40px">
                  <template #title>
                    <van-checkbox :name="v.value" icon-size="15px">{{v.label}}</van-checkbox>
                  </template>
                </van-cell>
              </template>
            </van-cell>
            
          </template>
        </van-cell-group>
      </van-checkbox-group>
    </div>
    <div class="select-button">
      <van-row gutter="20">
        <van-col :span="8"><van-button color="#ccc" block @click="reset">重置</van-button></van-col>
        <van-col :span="16"><van-button type="info" block @click="comfirm">确认</van-button></van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data () {
    return {
      labelCondition: 1,
      selectList: [],
      selectNameList: []
    }
  },
  methods: {
    conditionClick (value) {
      this.labelCondition = value
    },
    reset () {
      this.selectList = []
      this.selectNameList = []
    },
    comfirm () {
      this.$emit('selectLabel', {selectList:this.selectList.toString(),})
    },
    getSelectNameList (names) {
      this.selectNameList = []
      names.map(item => {
        this.getName(this.list, item)
      })
    },
    getName (list, value, fatherName) {
      list.map(val => {
        if (val.value == value) {
          this.selectNameList.push({
            label: fatherName ? (fatherName + '(' + (val.label.indexOf('(') > 0 ? val.label.split('(')[1].substring(0, val.label.lastIndexOf(')')) : val.label) + ')') : val.label,
            value: val.value
          })
        }
        if (val.children) {
          this.getName(val.children, value, val.label)
        }
      })
    },
    cancelLabel (value) {
      this.selectList = this.selectList.filter(item => {
        return item != value
      })
      this.selectNameList = this.selectNameList.filter(item => {
        return item.value != value
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.label-info {
  padding-bottom: 168px;
}
.select-label {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.select-condition {
  width: 100%;
  margin: 10px;
  border-radius: 10px;
  line-height: 80px;
  color: #387FF5;
  background-color: #fff;
  text-align: center;
}
.select-item {
  margin: 10px;
  line-height: 60px;
  color: #387FF5;
  background-color: #fff;
  padding: 0 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
}
.select-button {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .08);
}
.typeCheck {
  display: flex;
  height: 150px;
  line-height: 115px;
  padding-left: 40px;
  span {
    display: block;
    flex: 1;
    font-size: 30px;
  }
  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }
  span:nth-child(3) {
    margin-right: -187px;
    font-size: 28px;
    color: #576b95;
  }
}
.typeCheckBox .van-cell__title {
  margin-left: 20px;
}
.typeCheckBox .van-icon {
  position: absolute;
  right: 40px;
  bottom: 45%;
}
</style>
